<template>
  <div class="modal fade" ref="mdlAgregarRouter" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Agregar router a NOC</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="table-responsive">
            <table id="tblListadoNocs" class="table table-hover table-nowrap">
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th style="width: 100px" class="text-center">Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="router in routers" :key="router.id">
                  <td class="fw-semibold">
                    {{ router.nombre }}
                  </td>
                  <td class="text-center">
                    <button
                      class="btn btn-success btn-sm"
                      @click="agregarRouterNoc(router)"
                    >
                      <i class="mdi mdi-check-bold"></i>
                      Agregar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-light"
            data-bs-dismiss="modal"
          >
            <i class="mdi mdi-close"></i>
            Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal'
import RouterSrv from '@/services/RouterSrv'
import NocSrv from '@/services/NocSrv.js'

export default {
  name: 'MdlAgregarRouter',
  props: ['id_noc','ids_routers'],
  data() {
    return {
      routers: [],
    }
  },
  computed: {
    ids: function() {
      return this.ids_routers
    }
  },
  created() {},
  methods: {
    agregarRouterNoc(router) {
      let self = this, idsRouters = []

      idsRouters.push(router.id)

      NocSrv.agregarAccesoATravesDeLosRouters(self.id_noc, idsRouters).then(response => {
        self.$emit('refrescar-router')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron agregar los routers'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        var modal = Modal.getInstance(this.$refs.mdlAgregarRouter)
        modal.hide()
      })
    },

    cargarRouters: function() {
      var self = this

      RouterSrv.routersJSON().then(response => {
        var data = response.data
        
        let routers = data.filter(function(r) {
          let index = self.ids.findIndex(function (id) {
            return id == r.id
          })
          if(index == -1) return r
        })
        self.routers = routers


      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los routers'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    mostrar() {
      var self = this
      self.routers = []
      self.cargarRouters()
      var modal = new Modal(this.$refs.mdlAgregarRouter)
      modal.show()
    }
  }
}
</script>
