<template>
  <Layout :tituloPagina="'Network | NOCs | '+noc.descripcion+' | Permisos de acceso a través de los routers'">
    <div class="row">
      <div class="col-lg-6">
        <div class="text-right">
          <button class="btn btn-success btn-sm" @click="mostrarMdlAgregarRouter()">
            <span class="d-none d-sm-block">
              <i class="mdi mdi-plus-thick"></i>
              Agregar permiso de acceso a router
            </span>
            <span class="d-block d-sm-none">
              <i class="mdi mdi-plus-thick"></i>
            </span>
          </button>
        </div>

        <br>

        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              Routers con permiso de acceso para <strong>{{noc.descripcion}}</strong>
            </h4>
            <div>
              <a href="#" @click="cerrar()" onclick="return false;">
                <i class="mdi mdi-close text-dark"></i>
              </a>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table id="tblListadoNocs" class="table table-hover table-nowrap">
                <thead>
                  <tr>
                    <th style="width: 20px">
                      <input type="checkbox" ref="palancaElimnarTodosRouters" @change="palancaSeleccionarTodosRouters()"/>
                    </th>
                    <th>Nombre</th>
                    <th style="width: 180px" class="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="router in routers" :key="router.id">
                    <td>
                      <input type="checkbox" :checked="router._seleccionado" @change="palancaSeleccionarRouter(router)"/>
                    </td>
                    <td class="fw-semibold">
                      <router-link
                        :to="{
                          name: 'edicionRouter',
                          params: { id: router.id }
                        }"
                      >
                        {{ router.nombre }}
                      </router-link>
                    </td>
                    <td class="text-center">
                      <button
                        class="btn btn-outline-danger btn-sm"
                        @click="quitarPermisoNoc(router)"
                      >
                        <i class="mdi mdi-cancel"></i>
                        Quitar
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6 col-md-10 col-6">
            <button class="btn btn-danger" @click="quitarPermisosATodosRouter()" v-if="ids_routers_seleccionados.length > 0">
              <i class="mdi mdi-cancel"></i>
              Quitar permiso de acceso
            </button>
          </div>
          <div class="col-lg-6 col-md-2 col-6 text-right">
            <div class="btn-group">
              <button class="btn btn-secondary" @click="atras()">
                <i class="mdi mdi-chevron-left"></i>
                Atrás
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    

    <MdlAgregarRouter ref="MdlAgregarRouter" :id_noc="idNoc" :ids_routers="idsRouters"
      @refrescar-router="cargarRoutersNoc()"/>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue"
import NocSrv from '@/services/NocSrv.js'
import MdlAgregarRouter from './MdlAgregarRouter.vue'
export default {
  name: 'PermisosRoutersNoc',
  components: { Layout, MdlAgregarRouter },
  data() {
    return {
      noc: {
        id: null,
        descripcion: '',
        direccion_ip: ''
      },
      ids_routers_seleccionados: [],
      routers: []
    };
  },
  computed: {
    idNoc() {
      return this.$route.params.id
    },
    idsRouters() {
      var self = this
      let idsRouters = self.routers.map(function(r) {
        return r.id
      })

      return idsRouters
    }
  },

  created: function() {
    var self = this

    this.cargarNoc()
  },

  methods: {
    atras() {
      this.$router.go(-1)
    },

    cargarNoc: function() {
      var self = this,
        idNoc = this.idNoc

      NocSrv.nocJSON(idNoc).then(response => {
        self.noc = response.data
        self.cargarRoutersNoc()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el punto de acceso'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarRoutersNoc: function() {
      var self = this, idNoc = this.idNoc

      NocSrv.routersJSON(idNoc).then(response => {
        self.routers = response.data
        self.routers.forEach(function(router) {
          router._seleccionado = false
        });
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el punto de acceso'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cerrar: function() {
      this.atras()
    },

    mostrarMdlAgregarRouter: function() {
      var self = this
      self.$refs.MdlAgregarRouter.mostrar()
    },

    palancaSeleccionarRouter (router) {
      let self = this
      let index = self.ids_routers_seleccionados.indexOf(router.id)
      
      router._seleccionado = !router._seleccionado
      
      if (router._seleccionado && index == -1)
        self.ids_routers_seleccionados.push(router.id)
     
      if (!router._seleccionado && index >= 0)
        self.ids_routers_seleccionados.splice(index,1)
    },

    palancaSeleccionarTodosRouters() {
      let self = this
      let todosSeleccionados = self.ids_routers_seleccionados.length == self.routers.length
      
      if(!todosSeleccionados) {
        self.ids_routers_seleccionados = []
      
        self.routers.forEach(function(router){
          self.ids_routers_seleccionados.push(router.id)
          router._seleccionado = true
        })
      }
      else {
        self.ids_routers_seleccionados = []

        self.routers.forEach(function(router){
          router._seleccionado = false
        })
      }
    },

    quitarPermisoNoc: function(router) {
      let self = this, idsRouters = []

      idsRouters.push(router.id)

      NocSrv.eliminarAccesoATravesDeLosRouters(self.noc.id, idsRouters).then(response => {
        iu.msg.success('Se eliminó correctamente el acceso a través del router')

        self.cargarRoutersNoc()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron eliminar el acceso a traves de los router'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    quitarPermisosATodosRouter: function() {
      let self = this

      if( self.ids_routers_seleccionados.length == 0 ) {
        iu.msg.warning('No se puede quitar ningún router, ya que no se ha seleccionado ningún router')
        return
      }

      NocSrv.eliminarAccesoATravesDeLosRouters(self.noc.id, self.ids_routers_seleccionados).then(response => {
        iu.msg.success('Se eliminó correctamente el acceso a todos los routers')
        self.cargarRoutersNoc()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron eliminar el acceso a traves de los router'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>

</style>